<template>
  <main class="hair-removal">
    <section class="relative flex overflow-hidden -mx-10 -mt-10 lg:m-0">
      <div
        class="bg-center bg-cover absolute w-full h-full left-0 top-0"
        style="
          background-image: url(/assets/583cf35a-fc53-4ecc-90c1-fe86a126410b.jpg);
        "
      />
      <div
        class="relative bg-black bg-opacity-40 md:bg-opacity-30 flex flex-col w-full justify-center py-24 sm:py-40 2xl:py-64 px-10"
      >
        <div class="container w-full">
          <h1 class="text-4xl md:text-7xl font-bold text-white">
            Laser hårfjerning
          </h1>
          <hr class="w-16 my-8 border-2" />
          <h2 class="text-xl md:text-3xl font-semibold text-white">
            Effektiv hårfjerning med udstyr af højeste kvalitet.
          </h2>
        </div>
      </div>
    </section>
    <section class="py-24 lg:py-48">
      <div
        class="container flex flex-wrap-reverse sm:flex-wrap justify-between items-center"
      >
        <div class="w-full sm:w-6/12">
          <heading-2>Hårfjerning med laser</heading-2>
          <p>
            Permanent hårfjerning med laser er en effektiv og næsten smertefri
            måde at fjerne uønsket hårvækst over hele kroppen.
          </p>
          <p>
            Hos Skin Studio har vi flere års erfaring med permanent hårfjerning
            med laser til både kvinder og mænd. Vi er alle specialuddannede til
            at udføre hårfjerning med laser.
          </p>
        </div>
        <div class="w-full sm:w-5/12 mb-12 sm:m-0">
          <pic
            url="assets/c1fe9704-959a-4e6d-a308-e190ad41a670.jpg"
            alt="skinstudio treatment"
            :screens="[
              { screen: 300, width: 340, height: 340, fit: 'cover' },
              { screen: 768, width: 340, height: 400, fit: 'cover' },
              { screen: 1024, width: 400, height: 480, fit: 'cover' },
              { screen: 1280, width: 430, height: 520, fit: 'cover' },
              { screen: 1920, width: 500, height: 600, fit: 'cover' },
            ]"
          />
        </div>
      </div>
    </section>

    <section class="py-24 lg:py-40 px-10 -mx-10 lg:m-0 bg-gray-50">
      <div
        class="container flex flex-wrap md:flex-nowrap items-end gap-8 md:gap-16"
      >
        <div class="mx-auto">
          <stps-logo class="mx-auto md:m-0 h-20 md:h-22" />
        </div>
        <p class="-mb-1 text-sm text-center md:text-left">
          Skin Studio er godkendt hos styrelsen for patientsikkerhed. Dette er
          din garanti for en professionel behandling, samt at du er i trygge
          hænder, hos uddannet personale.
        </p>
      </div>
    </section>

    <section class="py-24 lg:py-48">
      <div
        class="container flex flex-wrap-reverse sm:flex-wrap justify-between items-center"
      >
        <div class="w-full sm:w-6/12">
          <heading-2>Udstyr af højeste kvalitet</heading-2>
          <p>
            For at opnå de bedste og mest risikofri resultater, bruger vi en
            laser der hedder Primelase HR til permanent hårfjerning. Primelase
            HR er en af de kraftigste maskiner på markedet.
          </p>
          <p>
            Den indeholder 3 forskellige bølgelængder (755, 810 og 1060) og er
            samtidig næsten smertefri og yderst skånsom mod huden.
          </p>
          <p>
            Primelase HR virker ved at hovedet på laseren nedkøler huden til 25
            grader, alt imens at laserstrålerne opvarmer hårsækkene til en
            temperatur på mellem 70-80 grader. Dette destruerer de hårsække der
            er i vækstfasen, og resultatet viser sig ca. 14 dage efter
            behandlingen.
          </p>
        </div>
        <div class="w-full sm:w-5/12 mb-12 sm:m-0">
          <pic
            url="assets/0b2d2927-cf01-404c-8061-1d486a661e7e.jpg"
            alt="skinstudio equipment"
            :screens="[
              { screen: 300, width: 340, height: 340, fit: 'cover' },
              { screen: 768, width: 340, height: 400, fit: 'cover' },
              { screen: 1024, width: 400, height: 480, fit: 'cover' },
              { screen: 1280, width: 430, height: 520, fit: 'cover' },
              { screen: 1920, width: 500, height: 600, fit: 'cover' },
            ]"
          />
        </div>
      </div>
    </section>
    <section class="py-24 lg:py-40 px-10 2xl:px-16 -mx-10 lg:m-0 bg-gray-50">
      <div class="container">
        <heading-2>Praktisk information</heading-2>

        <div class="grid grid-flow-row gap-10">
          <article>
            <h3 class="text-xl sm:text-2xl font-bold mb-4">
              Inden første behandling
            </h3>
            <p>
              Da vi er en klinik der er registreret ved Styrelsen for
              Patientsikkerhed kræver det at du gennemgår en konsultation ved en
              specialuddannet kosmetisk sygeplejerske. Der er ingen
              betænkningstid ved permanent hårfjerning og du må derfor få
              behandling lige efter konsultationen. Du er velkommen til at booke
              en behandling i forlængelse af din konsultation. Til den
              indledende konsultation vil du blive informeret om
              behandlingsforløbet samt få mulighed for at forventningsafstemme
              med den konsulterende behandler. En konsultation er gratis og
              uforpligtende, og kan
              <link-booking class="link">bookes her</link-booking>.
            </p>
          </article>

          <article>
            <h3 class="text-xl sm:text-2xl font-bold mb-4">
              Hvordan foregår behandlingen?
            </h3>
            <p>
              En behandling varer alt imellem 10-75 minutter, afhængig af hvilke
              områder man skal have behandlet. Din behandler byder dig velkommen
              og I snakker kort om behandlingsforløbet. Derefter skal du ligge
              dig på briksen. Dernæst påføres der en ultralydsgel, som får
              laseren til at glide mere behageligt langs huden, og så starter
              laserbehandlingen. Undervejs taler du hele tiden med din
              behandler, som justerer styrken på laseren efter din hudtype og
              hudfarve, og dette kan man også selv være med til at have en
              indflydelse på. Efter behandlingen må man gerne påføre creme og
              deodorant.
            </p>
          </article>

          <article>
            <h3 class="text-xl sm:text-2xl font-bold mb-4">
              Hvordan forbereder jeg mig bedst?
            </h3>
            <p>
              Minimum 4 uger inden behandlingen må du ikke have fjernet hår med
              pincet, voks,tråd eller lignende.
            </p>
            <ul>
              <li>
                Til selve behandlingen skal du have barberet de områder du
                ønsker behandlet. Barbering skal gerne ske 1-24 timer inden
                behandlingen.
              </li>
              <li>
                Området du skal have behandlet skal være fri for creme, make-up
                og deodoranter.
              </li>
              <li>
                Du må ikke have været i solarium, have solbadet eller brugt
                selvbruner en uge inden behandlingen. Du kan blive afvist til
                behandling hvis du er skoldet eller har mørk selvbruner på.
              </li>
              <li>
                Har du meget sart hud kan du evt. indtage en let smertestillende
                håndkøbsmedicin såsom Panodil en time inden din behandling.
                Rådfør dig med din læge hvis du er i tvivl om mængden.
              </li>
            </ul>
          </article>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import Heading2 from "../components/Heading2.vue";
import LinkBooking from "../components/LinkBooking.vue";
import Pic from "../components/Pic.vue";
import StpsLogo from "../components/StpsLogo.vue";
export default {
  name: "Hair removal",
  components: {
    Heading2,
    StpsLogo,
    LinkBooking,
    Pic,
  },
};
</script>
